import React from "react";
import img from "./assets/img.png";
import logo from "./assets/logo.png";
import background from './assets/bg.jpg'
const ComingSoon = () => {
  return (
    <div className="bg-black ">
      <div className="relative h-screen text-white   ">
        <img src={background} alt="Background" className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-black opacity-55"></div>
        <div className=" absolute inset-0 flex flex-col justify-center items-center text-center">
          <div className="flex flex-row justify-between gap-2 items-center h-screen">

            <div className="flex flex-col w-full   justify-center items-center ">
              <img src={logo} alt="logo" className="w-60 object-contain mb-10 lg:mb-5" />
              <h1 className="text-5xl lg:text-[100px] font-bold lg:text-left text-center leading-tight lg:leading-[94px] animate-bounce lg:animate-none">Launching soon</h1>
              <p className="text-lg lg:text-xl lg:text-left text-center lg:mt-10 mt-4" >
                Our Website is under construction. Stay tuned !
              </p>
            </div>
          </div>

          {/* Footer */}
          <div className="absolute bottom-4 md:text-xl text-xs">
            &copy; {new Date().getFullYear()} Nestico. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
